@font-face {
  font-family: "FiraSans";
  src: url("../../fonts/firasans/firasans-regular.woff2") format("woff2"),
    url("../../fonts/firasans/firasans-regular.woff") format("woff"),
    url("../../fonts/firasans/firasans-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "FiraSans";
  src: url("../../fonts/firasans/firasans-medium.woff2") format("woff2"),
    url("../../fonts/firasans/firasans-medium.woff") format("woff"),
    url("../../fonts/firasans/firasans-medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "FiraSans";
  src: url("../../fonts/firasans/firasans-semibold.woff2") format("woff2"),
    url("../../fonts/firasans/firasans-semibold.woff") format("woff"),
    url("../../fonts/firasans/firasans-semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "FiraSans";
  src: url("../../fonts/firasans/firasans-bold.woff2") format("woff2"),
    url("../../fonts/firasans/firasans-bold.woff") format("woff"),
    url("../../fonts/firasans/firasans-bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "FiraSans";
  src: url("../../fonts/firasans/firasans-black.woff2") format("woff2"),
    url("../../fonts/firasans/firasans-black.woff") format("woff"),
    url("../../fonts/firasans/firasans-black.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "FiraSans";
  src: url("../../fonts/firasans/firasans-extrabold.woff2") format("woff2"),
    url("../../fonts/firasans/firasans-extrabold.woff") format("woff"),
    url("../../fonts/firasans/firasans-extrabold.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}


@import "./typography";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

img {
  max-width: 100%;
}

:root {
  --title-font: FiraSans, Arial, sans-serif;
  --text-font: Inter, Arial, sans-serif;
  --white-color: #ffffff;
  --primary-color: #05BAF6;
  --primary-hover-color: #009ae1;
  --secondary-color: #F39224;
  --text-color-01: #020F12;
  --base-color-01: #989898;
  --font-button-text: 600 1.5rem var(--text-font);
  --font-textfield-text: 400 1.5rem var(--text-font);
  --font-heading-3: 700 2.25rem var(--title-font);
  --font-heading-4: 500 1.5rem var(--title-font);
  --font-feature-text: 400 0.875rem var(--text-font);
  --font-slider-text: 500 1.5rem var(--title-font);
  --font-logo-text-title: 500 1.5rem var(--text-font);
  --font-logo-text-pretitle: 700 0.6875rem var(--text-font);
  --font-header-list-text: 700 1.125rem var(--text-font);
  --font-header-contact-text: 500 1.125rem var(--text-font);
  --font-secondary-title-text: 900 3.25rem var(--title-font);
  --font-main-title-text: 900 5.875rem var(--title-font);
  --font-base-text: 400 1rem var(--text-font);
  --font-footer-title: 900 2.25rem var(--title-font);
  
  --xs-sm: 320px;
  --xs-max: 767px;
  --sm-min: 768px;
  --sm-max: 1023px;
  --md-min: 1024px;
  --md-max: 1279px;
  --lg-min: 1280px;
  --xlg-min: 1920px;
  --qhd-min: 1440px;
  --lg-max: 1919px;
}

@mixin flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin flex-column-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

@mixin flex-column-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

}
@mixin flex-column-stretch {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

@mixin flex-column-start-center {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

@mixin flex-column-center-center {
  @include flex-column-center;
  justify-content: center;
}

@mixin flex-column-start-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

@mixin flex-row-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex-row-end-start {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

@mixin flex-row-start-end {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

@mixin flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-row-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-between-center-center {
  @include flex-between-center;
  justify-content: center;
}

@mixin flex-row-between-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@mixin flex-row-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin flex-column-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

@mixin flex-row-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

@mixin flex-row-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-row-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-column-fill {
  min-height: 100%;
  min-width: 100%;
  @include flex-column-center-center;
}

@mixin flex-row-fill {
  height: 100%;
  width: 100%;
  @include flex-row-start-center;
}
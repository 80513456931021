@import "../../assets/styles/functions";

.searchfield {
  flex: 1;
  border-radius: pxToRem(10) 0 0 pxToRem(10);
  width: 100%;
  &-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: pxToRem(68) pxToRem(100);
    background: rgba(223, 244, 255, 0.43);
    backdrop-filter: blur(pxToRem(7.5));
    border-radius: pxToRem(20);
    @media screen and (min-width: 320px) and (max-width: 767px) {
      padding: pxToRem(20)
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      padding: pxToRem(35) pxToRem(50);
    }
  }
  &__button {
    width: pxToRem(94);
    height: pxToRem(94);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -35px;
    @media screen and (min-width: 320px) and (max-width: 1023px) {
      height: pxToRem(58);
      width: pxToRem(58);
    }
    svg {
      flex-shrink: 0;
      @media screen and (min-width: 320px) and (max-width: 1023px) {
        width: pxToRem(24);
      }
    }
  }
}
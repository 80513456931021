@import "../../assets/styles/functions";

.project-card {
  &-img {
    height: 200px;
    width: 200px;
    width: 100%;
    border-radius: pxToRem(20);
    background-size: cover !important;
    img {
      height: 100%;
      border-radius: pxToRem(20);
      width: 100%;
    }
  }
  a {
    text-decoration: none;
  }
  &__title {
    color: var(--text-color-01);
    font: var(--font-heading-4);
    line-height: 140%;
    margin-top: pxToRem(32);
    text-decoration: none;
    @media screen and (min-width: 320px) and (max-width: 767px) {
      font-size: 1rem;
      margin-top: pxToRem(20);
    }
  }
}
@import "../../assets/styles/functions";

.about {
  &-section {
    padding: pxToRem(80) 0;
    position: relative;
    @media screen and (min-width: 320px) and (max-width: 1023px) {
      padding: pxToRem(60) 0 0 0;
    }
  }
  &-line {
    position: absolute;
    top: 0;
    left: -43px;
    @media screen and (min-width: 320px) and (max-width: 767px) {
      display: none;
    }
    @media screen and (min-width: 768px) and (max-width: 1900px) {
      top: -20;
      left: -162px;
    }
  }
  &-block {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: flex-start;
    gap: pxToRem(100);
    @media screen and (min-width: 320px) and (max-width: 1023px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: pxToRem(40);
    }
    @media screen and (min-width: 1024px) and (max-width: 1880px) {
      gap: pxToRem(40);
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      @media screen and (min-width: 320px) and (max-width: 1023px) {
        align-items: flex-start;
        order: 0;
        img {
          display: none;
        }
      }
    }
    &-text {
      max-width: pxToRem(550);
      margin-top: pxToRem(60);
      @media screen and (min-width: 320px) and (max-width: 1023px) {
        margin-top: 0;
        max-width: none;
      }
      &__descr {
        font: var(--font-base-text);
        color: var(--text-color-01);
        line-height: 150%;
        &:not(:last-child) {
          margin-bottom: pxToRem(25);
        }
      }
      &__title {
        margin-bottom: pxToRem(22);
      }
      &__img {
        border-radius: pxToRem(20);
        margin-top: pxToRem(87);
        margin-right: -190px;
        @media screen and (min-width: 320px) and (max-width: 1023px) {
          margin-right: 0;
        }
      }
    }
    &-slider {
      margin-left: -240px;
      @media screen and (min-width: 320px) and (max-width: 1023px) {
        margin-left: 0;
        width: 100%;
        order: 1;
        div:first-child {
          max-width: 100%;
        }
      }
      @media screen and (min-width: 1024px) and (max-width: 1880px) {
        margin-left: 0;
      }
    }
    &-photo {
      margin-top: pxToRem(70);
      margin-right: -140px;
      @media screen and (min-width: 320px) and (max-width: 1023px) {
        display: none;
      }
      img {
        border-radius: pxToRem(20);
      }
    }
  }
}
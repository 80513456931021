@import "../../assets/styles/functions";

.feature {
  &-section {
    padding: pxToRem(100) 0;
    @media screen and (min-width: 320px) and (max-width: 767px) {
      padding: pxToRem(60) 0;
    }
  }
  &-block {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: pxToRem(60);
    @media screen and (min-width: 320px) and (max-width: 767px) {
      gap: pxToRem(20);
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      gap: pxToRem(30);
    }
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      gap: pxToRem(40);
    }
  }
  &-heading {
    margin-bottom: pxToRem(70);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: pxToRem(40);
    position: relative;
    @media screen and (min-width: 320px) and (max-width: 767px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: pxToRem(40);
      gap: pxToRem(20);
    }
    &__descr {
      text-align: right;
      font-family: var(--text-font);
      font-size: 1.5rem;
      line-height: 150%;
      font-weight: 500;
      @media screen and (min-width: 320px) and (max-width: 767px) {
        font-size: 0.875rem;
        text-align: left;
      }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        font-size: 1.25rem;
      }
    }
  }
  &-card {
    width: 30%;
    @media screen and (min-width: 320px) and (max-width: 550px) {
      width: 100%;
    }
    @media screen and (min-width: 551px) and (max-width: 1023px) {
      width: 47%;
    }
  }
}
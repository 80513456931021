@import "../../../assets/styles/functions";

.secondary-title {
  font: var(--font-secondary-title-text);
  line-height: 100%;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 1.5rem;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    font-size: 2.5rem;
  }
  &__dark {
    color: var(--text-color-01);
  }
  &__light {
    color: var(--white-color);
  }
}
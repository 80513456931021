@import "../../assets/styles/functions";

.textfield {
  padding: 0 pxToRem(50);
  height: pxToRem(94);
  border-radius: pxToRem(20);
  font: var(--font-textfield-text);
  color: var(--text-color-01);
  outline: none;
  border: pxToRem(2) solid var(--white-color);
  transition: all 0.2s;
  @media screen and (min-width: 320px) and (max-width: 1023px) {
    font-size: 1rem;
    height: pxToRem(58);
    border-radius: pxToRem(10);
    padding: 0 pxToRem(20);
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 0.875rem;
  }
  &::placeholder {
    color: var(--base-color-01);
  }
  &-wrapper {
    width: 100%;
    position: relative;
  }
  &__error {
    border: pxToRem(2) solid red;
  }
  &__helpertext {
    position: absolute;
    top: 100%;
    left: 0;
    font: var(--font-base-text);
    color: red;
    margin-top: pxToRem(10);
    white-space: pre-wrap;
    @media screen and (min-width: 320px) and (max-width: 767px) {
      font-size: pxToRem(12);
      margin-top: 0;
    }
  }
}
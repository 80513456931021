.swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  text-align: center;
  opacity: 1;
  background: transparent;
  color: transparent;
}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  top: none;
  bottom: 40px;
}

.slider {
  max-width: 100%;
  border-radius: 20px;
}


.slider.slider--simple {
  background: rgba(204, 242, 255, 0.73);
  backdrop-filter: blur(pxToRem(7.5));
}

.slider.slider--simple .swiper-pagination-bullet{
  border: 2px solid #07B6F0;
}

.slider.slider--simple .swiper-pagination-bullet-active{
  background: #07B6F0;
}

.slider.slider--full .swiper-pagination-bullet{
  border: 2px solid #F39224;
}

.slider.slider--full .swiper-pagination-bullet-active{
  background: #F39224;
}

.slider.slider--full {
  background: rgba(255, 241, 204, 0.73);
  backdrop-filter: blur(7.5px);
}
@import "../../assets/styles/functions";

.header {
  padding: pxToRem(25) 0;
  z-index: 2;
  position: relative;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding: pxToRem(15) 0;
  }
  // @media screen and (min-width: 320px) and (max-width: 990px) {
  //  position: fixed;
  // }
  &__active {
    position: sticky;
    top: 0;
    z-index: 2;
    background: var(--white-color);
  }
  &-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: pxToRem(20);
  }

  &-toggler {
    cursor: pointer;
    display: none;
    @media screen and (min-width: 320px) and (max-width: 990px) {
      display: flex;
    }
  }

  &-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &-text {
      margin-left: pxToRem(6);
      &__title {
        color: var(--primary-color);
        font: var(--font-logo-text-title);
        line-height: 100%;
        text-transform: uppercase;
      }
      &__pretitle {
        color: var(--primary-color);
        font: var(--font-logo-text-pretitle);
        line-height: 100%;
        letter-spacing: 0.455em;
        text-transform: uppercase;
      }
    }
  }

  &-social {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: pxToRem(8);
    flex-wrap: wrap;
    margin-left: auto;
    @media screen and (min-width: 320px) and (max-width: 990px) {
      display: none;
    }
    &-item {
      width: pxToRem(44);
      height: pxToRem(44);
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: scale(0.9);
      svg {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &-list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: pxToRem(40);
    flex: 1;
    flex-wrap: wrap;
    @media screen and (min-width: 320px) and (max-width: 990px) {
      display: none;
    }
    @media screen and (min-width: 991px) and (max-width: 1440px) {
      gap: pxToRem(20);
    }
    &__item {
      font: var(--font-header-list-text);
      cursor: pointer;
      color: var(--text-color-01);
      line-height: 110%;
      text-decoration: none;
    }
  }

  &-contact {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: pxToRem(6);
    @media screen and (min-width: 320px) and (max-width: 990px) {
      display: none;
    }
    @media screen and (min-width: 991px) and (max-width: 1440px) {
      justify-content: flex-end;
      align-items: flex-end;
    }
    &-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: pxToRem(6);
      flex-shrink: 0;
      &:first-child {
        order: 2;
      }
      &:last-child {
        order: 1;
      }
      &-link {
        font: var(--font-header-contact-text);
        color: var(--text-color-01);
        text-decoration: none;
        font-size: 14px;
        font-weight: 400;
      }
      &-icon {
        margin-top: pxToRem(0);
        width: 16px;
        height: 16px;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
@import "../../assets/styles/functions";

.modal-telegram {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  bottom: pxToRem(35);
  right: 3%;
  padding: pxToRem(48) pxToRem(32) pxToRem(32) pxToRem(32);
  border-radius: pxToRem(24);
  background: rgba(221, 244, 255, 0.80);
  backdrop-filter: blur(pxToRem(8));
  // background: rgba(253, 233, 211, 0.80);
  // backdrop-filter: blur(8px);
  width: pxToRem(340);
  z-index: 10;
  transition: all 0.2s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  @media screen and (min-width: pxToRem(320)) and (max-width: pxToRem(767)) {
    width: pxToRem(300);
    padding: pxToRem(32);
    border-radius: pxToRem(24);
  }

  &_active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  &__close {
    position: absolute;
    top: pxToRem(12);
    right: pxToRem(12);
    cursor: pointer;
  }

  &-body {
    margin-top: pxToRem(32);

    &-button {
      background: #F39224; 
      &:hover {
        background: #F39224;
      }
    }

    &__title {
      font-size: pxToRem(32);
      font-family: var(--title-font);
      color: var(--text-color-01);
      @media screen and (min-width: pxToRem(320)) and (max-width: pxToRem(767)) {
        font-size: pxToRem(24);
      }
    } 

    &__text {
      margin-top: pxToRem(10);
      font-family: var(--text-font);
      line-height: 150%;
      color: var(--text-color-01);
      font-size: pxToRem(15);
      @media screen and (min-width: pxToRem(320)) and (max-width: pxToRem(767)) {
        font-size: pxToRem(14);
      }
    }

    &__link {
      margin-top: pxToRem(32);
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
  }

}
@import "../../assets/styles/functions";

.carousel {
  &-heading {
    margin-bottom: pxToRem(70);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: pxToRem(40);
    @media screen and (min-width: 320px) and (max-width: 550px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: pxToRem(40);
    }
    &-text {
      text-align: right;
      @media screen and (min-width: 320px) and (max-width: 550px) {
        text-align-last: left;
      }
      &__descr {
        font-family: var(--text-font);
        color: var(--text-color-01);
        font-size: 1.5rem;
        line-height: 160%;
        font-weight: 500;
        @media screen and (min-width: 320px) and (max-width: 767px) {
          font-size: 1rem;
        }
      }
      &__link {
        font-family: var(--text-font);
        color: var(--primary-color);
        font-size: 1.5rem;
        line-height: 160%;
        font-weight: 500;
        text-decoration: none;
        border-bottom: pxToRem(2) solid var(--primary-color);
        @media screen and (min-width: 320px) and (max-width: 550px) {
          font-size: 0.875rem;
        }
        @media screen and (min-width: 551px) and (max-width: 1023px) {
          font-size: 1rem;
        }
      }
    }
  }
  &-section {
    padding: pxToRem(80) 0;
    z-index: 2;
    position: relative;
  }
}
@import "../../assets/styles/functions";

.carousel {
  &-item {
    padding: pxToRem(60) pxToRem(36) pxToRem(42) pxToRem(36);
    background: rgba(204, 242, 255, 0.73);
    backdrop-filter: blur(pxToRem(7.5));
    border-radius: pxToRem(20);
    height: 100%;
    @media screen and (min-width: 320px) and (max-width: 767px) {
      padding: pxToRem(30) pxToRem(25);
    }
    &-list {
      &-text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-clamp: 3;
        box-orient: vertical;
      }
      &__itemcircle {
        width: pxToRem(5);
        height: pxToRem(5);
        background: var(--text-color-01);
        flex-shrink: 0;
        margin-top: pxToRem(10);
        border-radius: 100%;
      }
      &__item {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: pxToRem(10);
        font: var(--font-base-text);
        color: var(--text-color-01);
        line-height: 140%;
        &:not(:last-child) {
          margin-bottom: pxToRem(10);
        }
      }
    }
    &__title {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 110%;
      color: var(--text-color-01);
      font-family: var(--title-font);
      margin-bottom: pxToRem(16);
      height: pxToRem(80);
      @media screen and (min-width: 320px) and (max-width: 767px) {
        font-size: 1.25rem;
        height: auto;
      }
    }
    &__subdvision {
      font-weight: 500;
      font-size: 1rem;
      line-height: 150%;
      color: var(--text-color-01);
      font-family: var(--text-font);
      margin-bottom: pxToRem(4);
    }
    &__city {
      font-weight: 400;
      font-size: 1rem;
      line-height: 150%;
      color: var(--text-color-01);
      font-family: var(--text-font);
    }
    &-list {
      margin: pxToRem(30) 0;
      margin-left: pxToRem(-16);
      &__item {
        font-weight: 400;
        font-size: 1rem;
        line-height: 130%;
        color: var(--text-color-01);
        font-family: var(--text-font);
        margin-left: pxToRem(18);
        @media screen and (min-width: 320px) and (max-width: 767px) {
          font-size: 0.875rem;
        }
        &:not(:last-child) {
          margin-bottom: pxToRem(10);
        }
      }
    }
    &__link {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-weight: 500;
      font-size: 1rem;
      line-height: 130%;
      color: var(--primary-color);
      font-family: var(--text-font);
      cursor: pointer;
      text-decoration: none;
    }
  }
}
@import "../../assets/styles/functions";

.search {
  &-section {
    position: relative;
    padding: pxToRem(90) 0;
    background: url("../../assets/images/search-bg.jpg") center center no-repeat;
    background-size: cover;
    border-radius: 0 0 pxToRem(80) pxToRem(80);
    @media screen and (min-width: 320px) and (max-width: 767px) {
      border-radius: 0 0 pxToRem(30) pxToRem(30);
      padding: pxToRem(70) 0;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      border-radius: 0 0 pxToRem(50) pxToRem(50);
    }
  }
  &-buttons {
    margin-top: pxToRem(24);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: pxToRem(24);
    flex-wrap: wrap;
    @media screen and (min-width: 320px) and (max-width: 1023px) {
      gap: pxToRem(16);
    }
  }
  &-heading {
    margin-bottom: pxToRem(25);
  }
  &-line {
    position: absolute;
    top: -236px;
    right: 0;
    z-index: 1;
    @media screen and (min-width: 320px) and (max-width: 767px) {
      display: none;
    }
  }
  &-block {
    z-index: 2;
    position: relative;
  }
  &__button {
    padding: pxToRem(20) pxToRem(40);
    border-radius: 10px;
    cursor: pointer;
    font: var(--font-button-text);
    color: var(--white-color);
    background: var(--primary-color);
    transition: all 0.2s;
    border: none;
    line-height: 110%;
    position: relative;
    text-decoration: none;
    @media screen and (min-width: 320px) and (max-width: 1023px) {
      font-size: 1rem;
    }
    &:hover {
      background: var(--primary-hover-color);
    }
  }
}
@import "../../assets/styles/functions";

.project {
  &-section {
    position: relative;
    padding: pxToRem(100) 0;
    @media screen and (min-width: 320px) and (max-width: 767px) {
      padding: pxToRem(60) 0;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      padding: pxToRem(80) 0;
    }
  }
  &-heading {
    margin-bottom: pxToRem(65);
    @media screen and (min-width: 320px) and (max-width: 767px) {
      margin-bottom: pxToRem(40);
    }
  }
  &-line {
    position: absolute;
    top: pxToRem(110);
    right: -1.35%;
    @media screen and (min-width: 320px) and (max-width: 767px) {
      display: none;
    }
  }
  &-block {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: pxToRem(60);
    @media screen and (min-width: 320px) and (max-width: 1023px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: pxToRem(30);
    }
  }
  &-items {
    display: grid;
    grid-template-columns: repeat(2, 45%);
    gap: pxToRem(60);
    @media screen and (min-width: 320px) and (max-width: 550px) {
      grid-template-columns: repeat(1, 100%);
      gap: pxToRem(40);
    }
    @media screen and (min-width: 551px) and (max-width: 1023px) {
      grid-template-columns: repeat(2, 48%);
      gap: pxToRem(20);
    }

    .project-item {
      &:nth-child(3) {
        background-size: contain;
      }
    }
  }
  &-slider {
    width: 100%;
    @media screen and (min-width: 320px) and (max-width: 1023px) {
      div:first-child {
        max-width: 100%;
      }
    }
  }
  &-item {
    max-width: 100%;
  }
}
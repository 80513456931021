@import "../../assets/styles/functions";

.slider {
  &-wrapper{
    max-width: pxToRem(480);
    @media screen and (min-width: 320px) and (max-width: 767px) {
      max-width: 100%;
    }
  }
  &-slide {
    &-img {
      width: 100%;
      height: pxToRem(470);
      background-size: cover;
      @media screen and (min-width: 320px) and (max-width: 1023px) {
        height: pxToRem(350);
      }
    }
    &-body {
      padding: pxToRem(50);
      @media screen and (min-width: 320px) and (max-width: 767px) {
        padding: pxToRem(50) pxToRem(30);
      }
      &__text {
        font: var(--font-slider-text);
        color: var(--text-color-01);
        margin-bottom: pxToRem(40);
        @media screen and (min-width: 320px) and (max-width: 767px) {
          font-size: 1rem;
          margin-bottom: pxToRem(40);
        }
      }
    }
  }
}
@font-face {
  font-family: "Inter";
  src: url("../../fonts/inter/Inter-Regular.woff2") format("woff2"),
    url("../../fonts/inter/Inter-Regular.woff") format("woff"),
    url("../../fonts/inter/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../../fonts/inter/Inter-Medium.woff2") format("woff2"),
    url("../../fonts/inter/Inter-Medium.woff") format("woff"),
    url("../../fonts/inter/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../../fonts/inter/Inter-Bold.woff2") format("woff2"),
    url("../../fonts/inter/Inter-Bold.woff") format("woff"),
    url("../../fonts/inter/Inter-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@import "../../assets/styles/functions";

.mobile-menu {
  display: flex;
  flex-direction: column; 
  align-items: center;
  background-color: var(--white-color);
  padding: 0;
  position: fixed;
  top: pxToRem(65);
  left: 0;
  width: 100%;
  height: 100%;
  height: 100%;
  z-index: 4;
  transition: all 0.2s;
  padding-top: pxToRem(70);
  &.closed {
    transform: translateX(-100%);
  }
  &.opened {
    transform: translateX(0%);
  }
  &-list {
    flex-direction: column;
    align-items: center;
    margin-bottom: pxToRem(40);
    display: flex;
    justify-content: flex-start;
    flex: none;
    gap: pxToRem(24);
  }
  &-contact {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: pxToRem(15);
    margin-top: pxToRem(40);
    display: flex;
  }
  &-social {
    display: flex;
    margin-left: 0;
  }
}
@import "../../assets/styles/functions";

.button {
  padding: pxToRem(20) pxToRem(40);
  border-radius: 10px;
  cursor: pointer;
  font: var(--font-button-text);
  color: var(--white-color);
  background: var(--primary-color);
  transition: all 0.2s;
  border: none;
  line-height: 110%;
  position: relative;
  @media screen and (min-width: 320px) and (max-width: 1023px) {
    font-size: 1rem;
  }
  &:hover {
    background: var(--primary-hover-color);
  }
}
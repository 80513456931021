@import "../../assets/styles/functions";

.footer {
  padding: pxToRem(75) 0;
  background: var(--secondary-color);
  color: var(--white-color);
  position: relative;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding: pxToRem(60) 0 pxToRem(60) 0;
  }
  &__copyright {
    font: var(--font-base-text);
  }

  &__title {
    margin-bottom: pxToRem(35);
    font: var(--font-footer-title);
    @media screen and (min-width: 320px) and (max-width: 767px) {
      font-size: pxToRem(24);
      margin-bottom: 0;
    }
  }
  &-line {
    position: absolute;
    top: -1px;
    left: 44.35%;
  }
  &-block {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: pxToRem(24);
    @media screen and (min-width: 320px) and (max-width: 600px) {
      flex-direction: column;
      justify-content: flex-start;
      gap: pxToRem(16);
    }
    &-text {
      &__title {
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 110%;
        font-family: var(--text-font);
        margin-bottom: pxToRem(15);
        @media screen and (min-width: 320px) and (max-width: 1023px) {
          font-size: 0.875rem;
        }
      }
      &__link {
        font-weight: 700;
        font-size: 2.625rem;
        line-height: 110%;
        text-decoration: none;
        font-family: var(--title-font);
        color: var(--white-color);
        @media screen and (min-width: 320px) and (max-width: 767px) {
          font-size: pxToRem(24);
        }
      }
    }
    &-social {
      margin-top: pxToRem(10);
      @media screen and (min-width: 320px) and (max-width: 600px) {
        margin-bottom: pxToRem(32);
      }
      &__title {
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 110%;
        font-family: var(--text-font);
        margin-bottom: pxToRem(24);
        @media screen and (min-width: 320px) and (max-width: 1023px) {
          font-size: 0.875rem;
          margin-bottom: pxToRem(20);
        }
      }
    }
  }

  &-social {
    &-item {
      width: pxToRem(60);
      height: pxToRem(60);
      border: pxToRem(3) solid var(--white-color);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      cursor: pointer;
      @media screen and (min-width: 320px) and (max-width: 1023px) {
        width: pxToRem(48);
        height: pxToRem(48);
      }
      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        transform: scale(0.8);
        svg {
          display: flex;
          justify-content: center;
          align-items: center;
          transform: scale(0.6);
        }
        @media screen and (min-width: 320px) and (max-width: 1023px) {
          width: pxToRem(32);
        }
      }
    }
    &-items {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: pxToRem(16);
      @media screen and (min-width: 320px) and (max-width: 767px) {
        gap: pxToRem(12);
      }
    }
  }

  &-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: pxToRem(48);
    @media screen and (min-width: pxToRem(320)) and (max-width: pxToRem(991)) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: pxToRem(12);
      margin-top: pxToRem(64);
    }
  }

  &-policy {
    font: var(--font-base-text);
    color: var(--white-color);
    text-decoration: none;
    border-bottom: pxToRem(1) solid var(--white-color);
  }
}
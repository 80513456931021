@import "../../assets/styles/functions";

.top-button {
  width: pxToRem(60);
  height: pxToRem(60);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white-color);
  border-radius: 100%;
  border: pxToRem(4) solid var(--primary-color);
  cursor: pointer;
  position: fixed;
  bottom: pxToRem(30);
  right: pxToRem(50);
  z-index: 2000;
  transition: all 0.2s;
  @media screen and (min-width: 320px) and (max-width: 550px) {
    right: pxToRem(15);
    bottom: pxToRem(15);
  }
  &.show {
    opacity: 1;
    visibility: visible;
  }
  svg {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@import "../../assets/styles/functions";

.feature-card {
  padding: pxToRem(60) pxToRem(40) pxToRem(80) pxToRem(40);
  background: rgba(255, 242, 207, 0.65);
  backdrop-filter: blur(pxToRem(7.5));
  border-radius: pxToRem(20);
  @media screen and (min-width: 320px) and (max-width: 1279px) {
    padding: pxToRem(35) pxToRem(25);
  }
  &__title {
    color: var(--text-color-01);
    font: var(--font-heading-3);
    margin-bottom: pxToRem(16);
    @media screen and (min-width: 320px) and (max-width: 767px) {
      font-size: 1.375rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1279px) {
      font-size: 1.75rem;
    }
  }
  &__descr {
    color: var(--text-color-01);
    font: var(--font-feature-text);
    line-height: 150%;
  }
  &__icon {
    height: pxToRem(140);
    margin-bottom: pxToRem(16);
    display: flex;
    flex-direction: column;
    svg {
      max-width: 100%;
    }
  }
}
.main-title {
  font: var(--font-main-title-text);
  line-height: 100%;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 3rem;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    font-size: 4rem;
  }
  &__primary {
    color: var(--primary-color);
  }
  &__secondary {
    color: var(--secondary-color);
  }
  &__light {
    color: var(--white-color);
  }
}